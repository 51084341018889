/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css } from "@emotion/react"
import Link from "@mui/material/Link"
import CardContent from "@mui/material/CardContent"
import theme from "../styles/theme"

interface PostCardProps {
  post: Pick<GatsbyTypes.Mdx, "id" | "slug"> & {
    readonly frontmatter: GatsbyTypes.Maybe<
      Pick<GatsbyTypes.MdxFrontmatter, "date" | "title" | "category" | "tags">
    >
  }
}

const PostCardCss = css`
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    color: ${theme.palette.text.secondary};
  }
  div {
    color: ${theme.palette.text.primary};
  }
  &:hover {
    p {
      color: ${theme.palette.text.disabled};
    }
    div {
      color: ${theme.palette.text.secondary};
    }
  }
`

const PosdCardDateCss = css`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 300;
`

const PosdCardTitleCss = css`
  margin-bottom: 3px;
  font-size: 22px;
  font-weight: 300;
`

const PostCard = ({ post }: PostCardProps) => {
  return (
    <CardContent css={PostCardCss}>
      <Link href={`/${post.slug}/`}>
        <p css={PosdCardDateCss}>{post.frontmatter?.date}</p>
        <div css={PosdCardTitleCss}>{post.frontmatter?.title}</div>
      </Link>
    </CardContent>
  )
}

export default PostCard
