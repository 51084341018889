/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css } from "@emotion/react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostCard from "../components/PostCard"

const postFeedCss = css`
  display: grid;
  grid-gap: 24px;
  margin: auto;
  padding: auto;
`

const Index = ({ data }: PageProps<GatsbyTypes.AllMdxQuery>) => {
  return (
    <Layout>
      <Seo type="blog" />
      <section className="post-feed" css={postFeedCss}>
        {data.allMdx.nodes.map(post => (
          <PostCard post={post} key={post.id} />
        ))}
      </section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query AllMdx {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          category
          tags
        }
        id
        slug
      }
    }
  }
`
